<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: signIn.vue
Description: This file contains vue component which has the form used for signing in user, superadmin, account manager and dealers into the pondlogs
-->
<template>
  <el-row type="flex" class="signin-card">
    <el-col :span="(24 - spanValue)">
      <dot-container>
        <template slot="header">
          <p>{{ $t("dont_have_an_account") }}</p>
        </template>
        <template slot="main">
          <p class="sign-up-message">{{ $t("eruvaka_company_description_culture") }}</p>
        </template>
        <template slot="footer">
          <er-button type="text" disabled>{{ $t("Usrs_sign_up") }}</er-button>
          <!-- <router-link to="/sign-up">
          </router-link> -->
        </template>
      </dot-container>
    </el-col>
    <el-col :span="spanValue">
      <card>
        <template slot="title">
          <h2>{{ $t("Harvest_log_in") }}</h2>
        </template>
        <template slot="subtitle">
          <p class="header">{{ $t("welcome_back_log_in") }}</p>
          <br />
          <p class="title">{{ $t("login_to_access_your_account") }}</p>
        </template>
        <template slot="form">
          <!-- <el-alert
            v-if="ehm__unhandledErrorMessage"
            :title="$t(ehm__unhandledErrorMessage)"
            type="error"
            style="margin-bottom: 10px"
            @close="ehm__handleAlertClose"
          ></el-alert> -->
          <el-alert
            v-if="error403"
            type="error"
            @close="handle403AlertClose"
            style="margin-bottom: 10px"
          >
            <i18n :path="errorMsg" tag="span">
              <template v-slot:action>
                <a href="/resend-link">{{ $t("resend_verify_link") }}</a>
              </template>
            </i18n>
          </el-alert>
          <el-alert
            v-if="error401PasswordExpired"
            type="error"
            @close="handle401AlertClose"
            style="margin-bottom: 10px"
          >
            <i18n :path="errorMsg" tag="span">
              <template v-slot:action>
                <a :href="`/reset-password-on-expiry`">{{ $t("reset_new_password") }}</a>
              </template>
            </i18n>
          </el-alert>
          <el-alert
            v-if="errorDealerLogin"
            type="error"
            @close="handleDealerLoginError"
            style="margin-bottom: 10px"
          >
            <i18n path="please_login_using_action" tag="span">
              <template v-slot:action>
                <a href="https://pondlogs.com" target="_blank">
                  {{ $t("pondlogs-v1") }}
                </a>
              </template>
            </i18n>
          </el-alert>
          <div class="locale-changer"></div>
          <el-form
            :model="user"
            :validate-on-rule-change="false"
            :rules="computedRules"
            v-loading="loading"
            element-loading-background="white"
            size="small"
            :ref="formName"
          >
            <div class="user-properties-element">
              <er-form-item
                prop="login_id"
                :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.login_id)"
              >
                <er-input
                  v-model="user.login_id"
                  :placeholder="$t('enter_user_name')"
                  :maxlength="userFieldLengths.user_name"
                >
                  <template slot="prepend">
                    <span class="material-icons-round icon--prefix">
                      account_circle
                    </span>
                  </template>
                </er-input>
              </er-form-item>
              <er-form-item
                prop="password"
                :error="
                  aekmm__castErrorKeyToLang(ehm__errMessagesObject.password)
                "
              >
                <er-input
                  v-model="user.password"
                  :placeholder="$t('Usrs_enter_pwd')"
                  show-password
                  ref="password"
                  :maxlength="userFieldLengths.max_password_length"
                  @keyup.native.enter="submitSignInDetails"
                >
                  <!--  -->
                  <template slot="prepend">
                    <span class="material-icons-round icon--prefix">
                      lock
                    </span>
                  </template>
                </er-input>
              </er-form-item>
              <el-row type="flex" justify="end">
                <router-link to="/forgot-password">
                  <er-button type="text" class="btn_forgot-password">{{
                    $t("Comn_forgot_ur_pwd")
                  }}</er-button>
                </router-link>
              </el-row>
              <el-row class="btn-container">
                <er-button
                  class="btn_submit"
                  size="small"
                  @click="submitSignInDetails"
                >
                  {{ $t("Harvest_log_in") }}
                  <span class="material-icons-round icon__suffix--space">
                    login
                  </span>
                </er-button>
              </el-row>
            </div>
          </el-form>
        </template>
      </card>
    </el-col>
    <div class="company_info_sign">
      <p style="display: flex; margin: 0px 0px 10px 25px">
        <span style="color: #ffffff; font-weight: 600">
          {{ $t("eruvaka-technologies", { year: new Date().getFullYear() }) }}
        </span>
        <span style="color: #ffffff; margin: 0px 5px">|</span>
        <a
          style="color: #b1c1c0; cursor: pointer; text-decoration: none"
          href="https://eruvaka.com/privacy-policy"
          target="_blank"
          >{{ $t("privacy-policy") }}
        </a>
        <span style="color: #ffffff; margin: 0px 5px">|</span>
        <a
          style="color: #b1c1c0; cursor: pointer; text-decoration: none"
          href="https://eruvaka.com/terms-and-conditions"
          target="_blank"
          >{{ $t("terms-and-conditions") }}
        </a>
      </p>
    </div>
  </el-row>
</template>
<script>
import User from "@/model/user.js";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import redirectsMixin from "@/mixins/redirectsMixin.js";
import authErrorKeyMapMixin from "@/mixins/authErrorKeyMapMixin";
import card from "./card";
import dotContainer from "./dotContainer";
import localStore from "@/utils/localStore";
import { userTypes } from "@/middleware/pageAccessManager";
import { mapActions } from "vuex";
import {
  erButton,
  // erForm,
  erInput,
  erFormItem
} from "@/components/base/basePondlogs.js";
import fieldLengths from '../../constants/fieldLengths';

export default {
  mixins: [errorHandlerMixin, redirectsMixin, authErrorKeyMapMixin],
  components: {
    erButton,
    // erForm,
    erInput,
    erFormItem,
    card,
    // googleSignIn,
    dotContainer
  },
  data: function () {
    return {
      userFieldLengths: fieldLengths,
      user: new User(),
      ehm__errMessagesObject: new User(),
      loading: false,
      formName: "signInForm",
      error403: false,
      resetCode: null,
      error401PasswordExpired: false,
      errorMsg: '',
      errorDealerLogin: false
    };
  },
  mounted() {
    const appTitle = document.getElementById("appTitle");
    appTitle.innerHTML = `${this.$t("Harvest_log_in")} | ${this.$t(
      "PondLogs"
    )}`;
    const username = this.$route.query.username;
    if (username) {
      this.user.login_id = username;
      // this.$nextTick(() => {
      //   this.$refs.password.elInput.focus();
      // });
    }
  },
  watch: {
    '$i18n.locale': function() {
      this.$refs[this.formName].resetFields();
      this.handle403AlertClose();
    }
  },
  computed: {
    computedRules() {
       return {
        login_id: [
            { required: true, message: this.$t("Comn_field_is_required", { field: this.$t('user_name') }), trigger: 'submit' }
          ],
        password: [
            { required: true, message: this.$t("Comn_field_is_required", { field: this.$t('Comn_password') }), trigger: 'submit' }
          ],
       }
      }
  },
  methods: {
    ...mapActions("auth", {
      signIn: "signIn",
      dealerSignOut: "dealerSignOut"
    }),
    handle403AlertClose: function () {
      this.error403 = false;
    },
    handle401AlertClose: function () {
      this.error401PasswordExpired = false;
    },
    handleDealerLoginError: function () {
      this.errorDealerLogin = false;
    },
    ehm__error403Handler: function (err) {
      const errorCodeObj = {
        INACTIVE_USER: 'inactive_user',
        ACCOUNT_IS_NOT_EXISTS: 'account_not_exists',
        PASSWORD_NOT_EXISTS: 'password_does_not_exists',
        BLOCKED_DUE_TO_FAILED_ATTEMPTS: 'account_blocked_due_to_multiple_attempts'
      }
      this.errorMsg = errorCodeObj[err.response.data.error_code] ? errorCodeObj[err.response.data.error_code] : 'email_or_mob_not_verified_with_resend_link'
      // switch (err.response.data.error_code) {
      //   case "INACTIVE_USER":
      //     this.errorMsg = 'inactive_user';
      //     break;
      //   case "ACCOUNT_IS_NOT_EXISTS":
      //     this.errorMsg = "account_not_exists"
      //     break;
      //   case "PASSWORD_NOT_EXISTS":
      //     this.errorMsg = "password_does_not_exists"
      //     break;
      //   case "BLOCKED_DUE_TO_FAILED_ATTEMPTS":
      //     this.errorMsg = "account_blocked_due_to_multiple_attemts"
      //     break;
      //   default:
      //     this.errorMsg = 'email_or_mob_not_verified_with_resend_link';
      // }
      this.error403 = true;
    },
    ehm__error401Handler: function (err, isAuthenticated) {
      const errorCodeObj = {
        PASSWORD_EXPIRED: "password_expired"
      }
      const failedCount = err.response.data?.signin_failed_count;
      this.errorMsg = errorCodeObj[err.response.data.error_code] ? errorCodeObj[err.response.data.error_code] : "unauthenticated_error_message";
      if (this.errorMsg === 'password_expired') {
        this.error401PasswordExpired = true;
        this.resetCode = err.response.data.rc
      } else {
        if (failedCount === 2) {
                this.errorMsg = "unauthenticated_error_message_second_attempt"
        } else if (failedCount === 3) {
                this.errorMsg = "unauthenticated_error_message_third_attempt"
        } else if (failedCount === 4) {
                this.errorMsg = "unauthenticated_error_message_fourth_attempt"
        }
          this.error403 = true;
      }
    },
    ehm__error422Handler: function (err) {
      const error_code = err.response.data.errors.details[0].error_code;
        if (error_code === 'INVALID_VALUE' || error_code === 'VALUE_DO_NOT_HAVE_MIN_CHAR' || error_code === 'VALUE_HAVE_MAX_CHAR') {
          this.errorMsg = "unauthenticated_error_message";
          this.error403 = true;
        }
    },
    submitSignInDetails() {
      this.error403 = false;
      this.$refs[this.formName].validate(async(valid) => {
        if (valid) {
          this.error401PasswordExpired = false;
          if (this.loading) {
            return;
          }
          this.loading = true;
          const loading = this.$loading({
            fullscreen: true,
            text: this.$t("Comn_loading_pls_wait")
          });
          try {
            this.ehm__unhandledErrorMessageV2 = null;
            this.errorDealerLogin = false;
            this.ehm__errMessagesObject = new User();
            await this.signIn(this.user);
            const userType = localStore.getItem("curr-user-type");
            if (localStore.getItem("show-welcome-dialog") === null) {
              localStore.setItem("show-welcome-dialog", true);
            }
            const unSetLoading = (
              messageTitle = "Usrs_success_msg",
              message = "User_sigin_success",
              type = "success"
            ) => {
              return (from, to, next) => {
                if ( type === 'User-error') {
                  this.loading = false;
                  loading.close();
                } else {
                  loading.close();
                  this.loading = false;
                  this.$notify({
                    title: this.$t(messageTitle),
                    message: this.$t(message),
                    duration: 5000,
                    type
                  });
                }
              };
            };
            if (userType === userTypes.super_admin) {
              this.$router.push(
                "/admin/",
                unSetLoading(),
                unSetLoading("failed", "Something_went_wrong", "error")
              );
            } else if (userType === userTypes.dealer) {
              // await this.dealerSignOut();
              // loading.close();
              // this.loading = false;
              // this.errorDealerLogin = true;
              this.$router.push("sign-in");
              this.$router.push(
                "/dealer/",
                unSetLoading(),
                unSetLoading("failed", "Something_went_wrong", "error")
              );
            } else if (userType === userTypes.account_manager) {
              this.$router.push(
                "/account-manager/",
                unSetLoading(),
                unSetLoading("failed", "Something_went_wrong", "error")
              );
            } else if (userType === userTypes.skretting_technician) {
              this.$router.push(
                "/skretting-technician/",
                unSetLoading(),
                unSetLoading("failed", "Something_went_wrong", "error")
              );
            } else {
              this.$router.push(
                "/user/",
                unSetLoading(),
                unSetLoading("failed", "Something_went_wrong", "User-error")
              );
            }
          } catch (err) {
            this.ehm__errorMessages(err, false);
            loading.close();
            this.loading = false;
          }
        }
      })
    }
  }
};
</script>
<style lang="scss">
@import "@/styles/mixins";
.signin-card {
  grid-column-start: 3 !important;
  grid-column-end: 6 !important;
  height: 100%;
  .el-alert .el-alert__description {
    margin: 0px;
  }
  .el-alert__closebtn {
    top: 10px;
  }
  .card {
    height: 100%;
  }
  .card .card-subtitle {
    text-align: center;
    line-height: 0.8;
    margin-top: 15px;
    .header {
      margin-bottom: 2px;
      @include responsiveProperty(font-size, 1.1em, 1.1em, 1em);
    }
    .title {
      @include responsiveProperty(font-size, 0.9em, 0.8em, 0.8em);
    }
  }
  .el-card__body {
    padding: 0rem 5em 2em 4em;
  }
  .btn_forgot-password {
    padding: 0px 0px 15px 0px;
  }
  .btn-container {
    min-width: 300px;
    line-height: 2;
    padding: 40px 10px 20px;
  }
  .company_info_sign {
    position: fixed;
    @include responsiveProperty(bottom, 2px, 4px, 6px);
    @include responsiveProperty(left, 2px, 4px, 6px);
  }
  @include respond-to(phone) {
    .sign-up-message {
      display: none;
    }
  }
}
</style>
